import React from "react";
import SEO from "../../../../../components/seo";
import SharePriceDataWidget from "../../../../../components/SharePriceDataWidget";

function PriceHorizontalWidget() {
  return (
    <>
      <SEO />
      <SharePriceDataWidget isHorizontal isBig />
    </>
  );
}

export default PriceHorizontalWidget;
